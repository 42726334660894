

























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ContractHoursForm from '../../models/contract-hours-form.model';
import { SelectListItem } from '../../models/select-list-item.model';
import { ToastHelper } from '../../../../shared/helpers/toast.helper';
import { inject } from 'inversify-props';
import { IContractsApiService, identifier } from '../../services/contracts.service';
import { Action, Getter } from 'vuex-class';
import ModeValueBase from '../../models/form-model.model';
import { DateHelper } from '../../../../shared/helpers/date.helper';
import dayjs from 'dayjs';

@Component
export default class EditHoursModal extends Vue {

    loading = true;
    trainingId: string | null = null;

    @Prop({ default: null }) contractId!: string;
    @Prop({ default: null }) hoursId!: string;
    @Prop() contractStartDate!:    string | null;
    @Prop() contractEndDate!:    string | null;
    
    @Getter
    private hourTypeList!: SelectListItem[];
    @Getter
    private memberTeamsList!: SelectListItem[];
    @Getter
    private clubId!:string;
    
    @Action
    private loadMemberTeams!: (date: string | null) => Promise<void>;
    @Action
    private loadHourTypes!: () => Promise<void>;

    @inject(identifier)
    private contractApiService!: IContractsApiService;

    dateModel = new ModeValueBase();
    timeFromModel = new ModeValueBase();
    timeToModel = new ModeValueBase();
    typeIdModel = new ModeValueBase();
    teamIdModel = new ModeValueBase();
    commentModel = new ModeValueBase();
    
    async modalVisibilityChanged(isVisible: boolean) : Promise<void> {
        if (isVisible) {
            await this.fetchModelToEdit();
        }
        else {
            this.$emit('onHide');
        }
    }

    async fetchModelToEdit() : Promise<void> {
        
        try {

            this.loading = true;
            this.trainingId = null;

            const apiModel = await this.contractApiService.getForUpdateContractHours(this.clubId, this.contractId, this.hoursId);
            const date = dayjs(apiModel.date).format('YYYY-MM-DD')
            this.dateModel.value = date;
            this.timeFromModel.value = dayjs(`${date} ${apiModel.startTime}`).format("HH:mm");
            this.timeToModel.value = dayjs(`${date} ${apiModel.endTime}`).format("HH:mm");
            this.typeIdModel.value = apiModel.typeId;
            this.teamIdModel.value = apiModel.teamId ?? null;
            this.trainingId = apiModel.trainingId ?? null;
            this.commentModel.value = apiModel.comment ?? null;

            await this.loadHourTypes();
            await this.loadMemberTeams(this.dateModel.value);
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, `${this.$t('shared.toast.errod')}`);
            this.$bvModal.hide('edit-hrs-modal');
        }
        finally {
            this.loading = false;
        }
    }

    async submit() : Promise<void> {
        try {
            if (this.validate()) {
                let model = new ContractHoursForm();
                model.date = dayjs(this.dateModel.value || '').format('YYYY-MM-DD'),
                model.startTime = this.timeFromModel.value || '',
                model.endTime = this.timeToModel.value || '',
                model.typeId = this.typeIdModel.value || '',
                model.teamId = this.teamIdModel.value,
                model.trainingId = this.trainingId,
                model.comment = this.commentModel.value;

                await this.contractApiService.updateContractHours(
                    this.clubId,
                    this.contractId,
                    this.hoursId,
                    model
                );

                ToastHelper.showSuccess(this.$bvToast, `${this.$t('contracts.addHours.form.submitSuccess')}`);
                this.$bvModal.hide('edit-hrs-modal');
                this.$emit('refresh');
            }
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, `${this.$t('shared.toast.errod')}`);
        }
    }

    hideModal() : void {
        this.$bvModal.hide('edit-hrs-modal');        
    }

    private validate() : boolean {
        let isValid = true;
        
        if (!this.dateModel.value) {
            this.dateModel.message = `${this.$t('contracts.addHours.form.fieldRequired')}`;
            isValid = false;
        }

        if (!this.timeFromModel.value) {
            this.timeFromModel.message = `${this.$t('contracts.addHours.form.fieldRequired')}`;
            isValid = false;
        }

        if (!this.timeToModel.value) {
            this.timeToModel.message = `${this.$t('contracts.addHours.form.fieldRequired')}`;
            isValid = false;
        }

        else if (this.timeFromModel.value && this.timeToModel.value && DateHelper.toSeconds(this.timeFromModel.value ?? '') >  DateHelper.toSeconds(this.timeToModel.value ?? ''))
        {
            this.timeToModel.message = `${this.$t('contracts.addHours.form.endEarlierThenStart')}`;
            isValid = false;
        }

        if (!this.typeIdModel.value) {
            this.typeIdModel.message = `${this.$t('contracts.addHours.form.fieldRequired')}`;
            isValid = false;
        }

        return isValid;
    }
    
    private async dateChanged(val: string) : Promise<void> {
        this.dateModel.value = val;
        this.dateModel.message = null;

        if (val) {
            await this.loadMemberTeams(val);
        }
    }

    private timeFromChanged(val: string) : void {
        this.timeFromModel.value = val;
        this.timeFromModel.message = null;
    }

    private timeToChanged(val: string) : void {
        this.timeToModel.value = val;
        this.timeToModel.message = null;
    }
}
