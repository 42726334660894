var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _vm.contractDetails === null
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col mb-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "alert alert-warning",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("contracts.details.notFound")) + " "
                      ),
                    ]
                  ),
                ]),
              ])
            : _c("div", [
                _c("h5", { staticClass: "col-12 mt-3 mb-4" }, [
                  _vm._v(_vm._s(_vm.contractDetails.name)),
                ]),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "b-card",
                      { staticClass: "row mb-4" },
                      [
                        _c("b-card-body", [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c("strong", [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("contracts.details.startDate")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDate(_vm.contractDetails.startDate)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c("strong", [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("contracts.details.endDate")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDate(_vm.contractDetails.endDate)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c("strong", [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("contracts.details.iban")) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _vm._v(
                                " " + _vm._s(_vm.contractDetails.iban) + " "
                              ),
                            ]),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c("strong", [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "contracts.details.acocuntHolder"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.contractDetails.accountHolder) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c("strong", [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "contracts.details.responsibleUser"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.contractDetails.responsibleUser) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c("strong", [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("contracts.details.amount")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getAmountWithType(
                                      _vm.contractDetails.amount,
                                      _vm.contractDetails.amountType
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm.showHours
                      ? _c("div", [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("contracts.details.registrtions")
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _vm.contractDetails.isActive
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.book-hrs-modal",
                                          modifiers: { "book-hrs-modal": true },
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-primary float-right",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "contracts.details.hourRegistrationBtn"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("contracts.details.total")) +
                                  ": " +
                                  _vm._s(_vm.totalRegisteredHours) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("contracts.details.totalPaid")
                                  ) +
                                  ": € " +
                                  _vm._s(_vm.totalPaid) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "row mt-4" },
                            [
                              _c("b-table", {
                                staticClass: "table-hover bg-white border",
                                attrs: {
                                  "show-empty": "",
                                  "empty-text": _vm.$t(
                                    "contracts.details.hoursTable.noHours"
                                  ),
                                  items: _vm.registeredHours,
                                  fields: _vm.tableHeaders,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(date)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.getHoursDate(data)) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(registeredDate)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getRegisteredDate(
                                                  data.value
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(amountTotal)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " €" +
                                              _vm._s(
                                                _vm.parsePrice(data.value)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(approved)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getApprovedInfo(data)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(paid)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getPaidInfo(data.value)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(id)",
                                      fn: function (data) {
                                        return [
                                          data.item.canEdit &&
                                          _vm.contractDetails.isActive
                                            ? _c("div", [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-pen pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showEditHourModal(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-trash pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.confirmDeleteHour(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                }),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2226451174
                                ),
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mb-4" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "Contracts" } } },
                      [
                        _c(
                          "span",
                          { staticClass: "badge badge-dark badge-pill" },
                          [_c("i", { staticClass: "fa fa-angle-left" })]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("contracts.details.backBtn")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
          _c("book-hours-modal", {
            attrs: {
              "contract-id": _vm.contractId,
              "contract-start-date": _vm.contractStartDate,
              "contract-end-date": _vm.contractEndDate,
            },
            on: { refresh: _vm.reloadDetails },
          }),
          _c("edit-hours-modal", {
            attrs: {
              "contract-id": _vm.contractId,
              "hours-id": _vm.hoursToEdit,
              "contract-start-date": _vm.contractStartDate,
              "contract-end-date": _vm.contractEndDate,
            },
            on: {
              refresh: _vm.reloadDetails,
              onHide: _vm.onEditHoursModalHide,
            },
          }),
          _c("confirm-delete-modal", {
            attrs: { message: _vm.$t("contracts.details.deleteConfirmText") },
            on: { onConfirm: _vm.deleteHours },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }