
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContractTrainings } from '../../models/contract-trainings.model';
import { DateHelper } from '@/shared/helpers/date.helper';

@Component
export default class ContractTrainingList extends Vue {

    @Prop({ default: []})  readonly trainings!: ContractTrainings[];
    @Prop({ default: 10 }) readonly pageSize!: number;
    @Prop({ default: 1 })  readonly currentPage!: number;
    @Prop({ default: false }) busy!: boolean;

    get tableHeaders() : { key: string, label: string, thStyle: any, tdClass: any }[] {
        return [
            { key: 'date', label: `${this.$t('contracts.addHours.trainings.dateHeader')}`, thStyle: { width: "15%" }, tdClass: '' },
            { key: 'startTime', label: `${this.$t('contracts.addHours.trainings.startTimeHeader')}`, thStyle: { width: "15%", textAlign: "center" }, tdClass: 'text-center' },
            { key: 'endTime', label: `${this.$t('contracts.addHours.trainings.endTimeHeader')}`, thStyle: { width: "15%", textAlign: "center" }, tdClass: 'text-center' },
            { key: 'teams', label: `${this.$t('contracts.addHours.trainings.teamsHeader')}`, thStyle: { width: "30%", textAlign: "center" }, tdClass: 'text-center' },
            { key: 'calendarEventDateId', label: `${this.$t('contracts.addHours.trainings.registerHoursHeader')}`, thStyle: { width: "25%", textAlign: "center" }, tdClass: 'text-center' }
        ];
    }

    get tableValues() : ContractTrainings[] {
        return this.trainings;
    }

    getDate(value: Date) : string {
        return DateHelper.parseDate(value);
    }

    getTime(value: Date) : string {
        return DateHelper.parseTime(value);
    }

    selectTraining(calendarEventDateId: string) : void {
        this.$emit('onTrainingSelect', calendarEventDateId);
    }
}
