





















































































import { Component,  Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ContractHoursForm from '../../models/contract-hours-form.model';
import { SelectListItem } from '../../models/select-list-item.model';
import { ToastHelper } from '../../../../shared/helpers/toast.helper';
import { inject } from 'inversify-props';
import { IContractsApiService, identifier } from '../../services/contracts.service';
import ModeValueBase from '../../models/form-model.model';
import { DateHelper } from '../../../../shared/helpers/date.helper';
import dayjs from 'dayjs';


@Component({})
export default class BookHoursForm extends Vue {

    @Prop() contractId!: string;

    @Prop() date!:       string | null;
    @Prop() timeFrom!:   string | null;
    @Prop() timeTo!:     string | null;
    @Prop() trainingId!: string | null;
    @Prop() calendarEventDateId!: string | null;
    @Prop() minDate!:    string | null;
    @Prop() maxDate!:    string | null;
    @Prop() teamId!:     string | null;

    dateModel = new ModeValueBase();
    timeFromModel = new ModeValueBase();
    timeToModel = new ModeValueBase();
    typeIdModel = new ModeValueBase();
    teamIdModel = new ModeValueBase();
    commentModel = new ModeValueBase();
    
    @inject(identifier)
    private contractApiService!: IContractsApiService;

    @Getter
    private hourTypeList!: SelectListItem[];
    @Getter
    private memberTeamsList!: SelectListItem[];
    @Getter
    private clubId!:string;

    @Action
    private loadMemberTeams!: (date: string | null) => Promise<void>;

    async created() {
        if (this.date) {
            await this.loadMemberTeams(this.date);
        }

        this.dateModel.value = this.date;
        this.timeFromModel.value = this.timeFrom;
        this.timeToModel.value = this.timeTo;
        this.teamIdModel.value = this.teamId;
    }

    async submit() : Promise<void> {
        try {
            if (this.validate()) {
                let model = new ContractHoursForm();
                model.date = dayjs(this.dateModel.value || '').format('YYYY-MM-DD');
                model.startTime = this.timeFromModel.value || '';
                model.endTime = this.timeToModel.value || '';
                model.typeId = this.typeIdModel.value || '';
                model.teamId = this.teamIdModel.value;
                model.trainingId = this.trainingId;
                model.calendarEventDateId = this.calendarEventDateId;
                model.comment = this.commentModel.value;

                await this.contractApiService.addContractHours(
                    this.clubId,
                    this.contractId,
                    model
                );

                ToastHelper.showSuccess(this.$bvToast, `${this.$t('contracts.addHours.form.submitSuccess')}`);
                this.$emit('onSubmit');
            }
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
    }

    private validate() : boolean {
        let isValid = true;
        
        if (this.dateModel.value === null || this.dateModel.value === undefined || this.dateModel.value === '') {
            this.dateModel.message = `${this.$t('contracts.addHours.form.fieldRequired')}`;
            isValid = false;
        }

        if (this.timeFromModel.value === null || this.timeFromModel.value === undefined || this.timeFromModel.value === '') {
            this.timeFromModel.message = `${this.$t('contracts.addHours.form.fieldRequired')}`;
            isValid = false;
        }

        if (this.timeToModel.value === null || this.timeToModel.value === undefined || this.timeToModel.value === '') {
            this.timeToModel.message = `${this.$t('contracts.addHours.form.fieldRequired')}`;
            isValid = false;
        }        

        else if (this.timeFromModel.value && this.timeToModel.value && DateHelper.toSeconds(this.timeFromModel.value ?? '') >  DateHelper.toSeconds(this.timeToModel.value ?? ''))
        {
            this.timeToModel.message = `${this.$t('contracts.addHours.form.endEarlierThenStart')}`;
            isValid = false;
        }

        if (this.typeIdModel.value === null || this.typeIdModel.value === undefined || this.typeIdModel.value === '') {
            this.typeIdModel.message = `${this.$t('contracts.addHours.form.fieldRequired')}`;
            isValid = false;
        }

        return isValid;
    }

    private async dateChanged(val: string) : Promise<void> {
        this.dateModel.value = val;
        this.dateModel.message = null;

        if (val !== '') {
            await this.loadMemberTeams(val);
        }
    }

    private timeFromChanged(val: string) : void {
        this.timeFromModel.value = val;
        this.timeFromModel.message = null;
    }

    private timeToChanged(val: string) : void {
        this.timeToModel.value = val;
        this.timeToModel.message = null;
    }
}
