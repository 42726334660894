var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h6", { staticClass: "col-12 mt-3 mb-4 pl-0" }, [
        _vm._v(
          _vm._s(_vm.$t("contracts.addHours.trainings.overviewMyTrainings"))
        ),
      ]),
      _c("b-table", {
        attrs: {
          items: _vm.tableValues,
          fields: _vm.tableHeaders,
          "total-rows": _vm.pageSize,
          "current-page": _vm.currentPage,
          busy: _vm.busy,
          striped: "",
          hover: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(date)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(_vm.getDate(data.item.startDate)) + " "),
              ]
            },
          },
          {
            key: "cell(startTime)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(_vm.getTime(data.item.startDate)) + " "),
              ]
            },
          },
          {
            key: "cell(endTime)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(_vm.getTime(data.item.endDate)) + " "),
              ]
            },
          },
          {
            key: "cell(field)",
            fn: function (data) {
              return [_vm._v(" " + _vm._s(data.item.field) + " ")]
            },
          },
          {
            key: "cell(teams)",
            fn: function (data) {
              return _vm._l(data.item.teams, function (team) {
                return _c("div", { key: team.id }, [
                  _c("span", [_vm._v(_vm._s(team.name))]),
                ])
              })
            },
          },
          {
            key: "cell(calendarEventDateId)",
            fn: function (data) {
              return [
                data.item.hasRegisteredHours
                  ? [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "contracts.addHours.trainings.hoursAlreadyBooked"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fas fa-check-circle",
                        staticStyle: { color: "green" },
                      }),
                    ]
                  : [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.selectTraining(
                                data.item.calendarEventDateId
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "contracts.addHours.trainings.bookHoursBtn"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }