var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "3" } }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("contracts.addHours.form.date"))),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { sm: "9" } },
                [
                  _c("date-picker", {
                    attrs: {
                      initValue: _vm.dateModel.value,
                      errorMsg: _vm.dateModel.message,
                      "min-date": _vm.minDate,
                      "max-date": _vm.maxDate,
                    },
                    on: { onChange: _vm.dateChanged },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "3" } }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("contracts.addHours.form.timeFrom"))),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { sm: "9" } },
                [
                  _c("clock-picker", {
                    attrs: {
                      initValue: _vm.timeFromModel.value,
                      errorMsg: _vm.timeFromModel.message,
                    },
                    on: { onChange: _vm.timeFromChanged },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "3" } }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("contracts.addHours.form.timeTo"))),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { sm: "9" } },
                [
                  _c("clock-picker", {
                    attrs: {
                      initValue: _vm.timeToModel.value,
                      errorMsg: _vm.timeToModel.message,
                    },
                    on: { onChange: _vm.timeToChanged },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "3" } }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("contracts.addHours.form.type"))),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { sm: "9" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      state: _vm.typeIdModel.state,
                      options: _vm.hourTypeList,
                    },
                    on: {
                      change: function ($event) {
                        _vm.typeIdModel.message = null
                      },
                    },
                    model: {
                      value: _vm.typeIdModel.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.typeIdModel, "value", $$v)
                      },
                      expression: "typeIdModel.value",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(" " + _vm._s(_vm.typeIdModel.message) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "3" } }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("contracts.addHours.form.team"))),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { sm: "9" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      state: _vm.teamIdModel.state,
                      options: _vm.memberTeamsList,
                    },
                    on: {
                      change: function ($event) {
                        _vm.teamIdModel.message = null
                      },
                    },
                    model: {
                      value: _vm.teamIdModel.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.teamIdModel, "value", $$v)
                      },
                      expression: "teamIdModel.value",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(" " + _vm._s(_vm.teamIdModel.message) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "3" } }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("contracts.addHours.form.comment"))),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { sm: "9" } },
                [
                  _c("b-form-textarea", {
                    attrs: { rows: "4" },
                    model: {
                      value: _vm.commentModel.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.commentModel, "value", $$v)
                      },
                      expression: "commentModel.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass: "w-100",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: { variant: "outline-secondary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("onCancel")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("contracts.addHours.form.cancel")))]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: { variant: "success", type: "submit" },
                      },
                      [_vm._v(_vm._s(_vm.$t("contracts.addHours.form.save")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }