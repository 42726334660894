export default class ModeValueBase {
    constructor(value: string | null = null) {
        this.value = value;
    }

    public value: string | null = null;
    public state: boolean | null = null;
    
    private msg: string | null = null;

    get message() : string | null {
        return this.msg;
    }
    set message(val: string | null) {
        this.msg = val;
        this.state = val === '' || val === null ? null : false;
    }
}