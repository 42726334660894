export  default class ContractHoursForm {

    date = '';
    startTime = '';
    endTime = '';
    typeId = '';
    teamId?: string | null = null;
    trainingId?: string | null = null;
    calendarEventDateId?: string | null = null;
    comment?: string | null = null;
}