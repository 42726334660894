











































































import { inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ContractTrainings } from '../../models/contract-trainings.model';
import { ITrainingsApiService } from '../../services/trainings.service';
import BookHoursForm from './BookHoursForm.vue';
import ContractTrainingList from './TrainingsList.vue';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import ModeValueBase from '../../models/form-model.model';
import dayjs from 'dayjs';

@Component({
    components: {
        'training-list-component': ContractTrainingList,
        'book-hours-form': BookHoursForm
    }
})
export default class BookHoursModal extends Vue {

    loading = true;
    tableLoading = false;
    showBookedTrainings = true;
    hasAnyTrainings = false;
    showForm = false;
    
    dateFromFilter = new ModeValueBase();
    dateToFilter = new ModeValueBase();
    selectedTrainingId: string | null = null;
    selectedCalendarEventDateId: string | null = null;
    selectedTeamId: string | null = null;
    selectedDate: string | null = null;
    selectedTimeFrom: string | null = null;
    selectedTimeTo: string| null = null;

    @Prop() contractId!: string;
    @Prop() contractStartDate!: string | null;
    @Prop() contractEndDate!: string | null;

    @inject()
    private trainingsApiService!: ITrainingsApiService;

    @Action
    private loadHourTypes!: () => Promise<void>;

    pager = {
        pageNumber: 1,
        pageSize: 10,
        totalRows: 0
    };

    trainings: ContractTrainings[] = [];

    @Getter
    private clubId!: string;


    async created() : Promise<void> {
        await this.loadHourTypes();
        this.dateFromFilter.value = dayjs(new Date()).add(-1, 'days').format('YYYY-MM-DD');
        this.dateToFilter.value = dayjs(new Date()).add(7, 'days').format('YYYY-MM-DD');
    }

    async reloadTrainings(globalLoading = true) : Promise<void> {
        try {
            if (globalLoading) {
                this.loading = true;
            }
            else {
                this.tableLoading = true;
            }

            const resultPaged = await this.trainingsApiService.getTrainings(this.clubId, 
                this.pager.pageNumber, 
                this.pager.pageSize, 
                this.showBookedTrainings, 
                this.contractId,
                this.dateFromFilter.value,
                this.dateToFilter.value);
            
            this.hasAnyTrainings = resultPaged.hasAnyTrainings;
            this.trainings = resultPaged.pagedTrainings.items;
            this.pager.totalRows = resultPaged.pagedTrainings.page.totalEntries;
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
        finally {
            this.loading = false;
            this.tableLoading = false;
        }
    }

    async modalVisibilityChanged(isVisible: boolean) : Promise<void> {
        if (isVisible) {
            this.showForm = false;
            this.pager = {
                pageNumber: 1,
                pageSize: 10,
                totalRows: 0
            };

            await this.reloadTrainings();
        }
    }

    async onPaginationChange(page: number) : Promise<void> {
        this.pager.pageNumber = page || 1;
        await this.reloadTrainings(false);
    }

    selectShowForm(calendarEventDateId: string | null) : void {
        this.preparePreselects(calendarEventDateId);
        this.showForm = true;
    }

    hideModal(): void {
        this.$bvModal.hide('book-hrs-modal');
    }

    submitted() : void {        
        this.$bvModal.hide('book-hrs-modal');
        this.$emit('refresh');
    }

    preparePreselects(calendarEventDateId: string | null) : void {
        const index = calendarEventDateId === null ? -1 : this.trainings.findIndex(x => x.calendarEventDateId === calendarEventDateId);

        this.selectedTrainingId = index === -1 ? null : this.trainings[index].id;
        this.selectedCalendarEventDateId = index === -1 ? null : this.trainings[index].calendarEventDateId;
        this.selectedTeamId = index === -1 ? null : (this.trainings[index].teams.length > 0 ? this.trainings[index].teams[0].id : null)
        this.selectedDate =  index === -1 ? null : dayjs(this.trainings[index].startDate).format("YYYY-MM-DD");
        this.selectedTimeFrom =  index === -1 ? null : dayjs(this.trainings[index].startDate).format("HH:mm");
        this.selectedTimeTo =  index === -1 ? null : dayjs(this.trainings[index].endDate).format("HH:mm");
    }

    dateFromChanged(val: string) : void {
        const contractStartDate = dayjs(this.contractStartDate);
        const valDate = dayjs(val, 'YYYY-MM-DD');

        if (valDate < contractStartDate) {
            this.dateFromFilter.message = `${this.$t('contracts.addHours.trainings.wrongStartDate')}`;
        }
        else { 
            this.dateFromFilter.value = val;
            this.dateFromFilter.message = null;
            this.reloadTrainings(false);
        }
    }

    dateToChanged(val: string) : void {
        const contractEndDate = dayjs(this.contractEndDate);
        const valDate = dayjs(val);

        if (contractEndDate && valDate > contractEndDate) {
            this.dateToFilter.message = `${this.$t('contracts.addHours.trainings.wrongEndDate')}`;
        }
        else { 
            this.dateToFilter.value = val;
            this.dateToFilter.message = null;
            this.reloadTrainings(false);
        }
    }
}
