var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "book-hrs-modal",
      attrs: {
        "hide-footer": "",
        id: "book-hrs-modal",
        size: "lg",
        title: _vm.$t("contracts.addHours.modalHeader"),
      },
      on: { change: _vm.modalVisibilityChanged },
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "loader-container" }, [_c("Loader")], 1)
        : _vm.hasAnyTrainings && !_vm.showForm
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "mb-2 mt-2" },
                [
                  _c("b-col", { attrs: { sm: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { switch: "" },
                            on: { change: _vm.reloadTrainings },
                            model: {
                              value: _vm.showBookedTrainings,
                              callback: function ($$v) {
                                _vm.showBookedTrainings = $$v
                              },
                              expression: "showBookedTrainings",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "contracts.addHours.trainings.showBooked"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "4" } },
                          [
                            _c("b-col", [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("contracts.addHours.form.timeFrom")
                                  ) + ":"
                                ),
                              ]),
                            ]),
                            _c(
                              "b-col",
                              [
                                _c("date-picker", {
                                  attrs: {
                                    initValue: _vm.dateFromFilter.value,
                                    errorMsg: _vm.dateFromFilter.message,
                                    "min-date": _vm.contractStartDate,
                                    "max-date": _vm.contractEndDate,
                                  },
                                  on: { onChange: _vm.dateFromChanged },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "4" } },
                          [
                            _c("b-col", [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("contracts.addHours.form.timeTo")
                                  ) + ":"
                                ),
                              ]),
                            ]),
                            _c(
                              "b-col",
                              [
                                _c("date-picker", {
                                  attrs: {
                                    initValue: _vm.dateToFilter.value,
                                    errorMsg: _vm.dateToFilter.message,
                                    "min-date": _vm.contractStartDate,
                                    "max-date": _vm.contractEndDate,
                                  },
                                  on: { onChange: _vm.dateToChanged },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.selectShowForm(null)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "contracts.addHours.trainings.addManualy"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("training-list-component", {
                attrs: {
                  trainings: _vm.trainings,
                  "page-size": _vm.pager.pageSize,
                  "current-page": _vm.pager.pageNumber,
                  busy: _vm.tableLoading,
                },
                on: { onTrainingSelect: _vm.selectShowForm },
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.pager.totalRows,
                      "per-page": _vm.pager.pageSize,
                    },
                    on: { change: _vm.onPaginationChange },
                    model: {
                      value: _vm.pager.pageNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.pager, "pageNumber", $$v)
                      },
                      expression: "pager.pageNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("book-hours-form", {
                attrs: {
                  "contract-id": _vm.contractId,
                  date: _vm.selectedDate,
                  "time-from": _vm.selectedTimeFrom,
                  "time-to": _vm.selectedTimeTo,
                  "min-date": _vm.contractStartDate,
                  "max-date": _vm.contractEndDate,
                  "training-id": _vm.selectedTrainingId,
                  "calendar-event-date-id": _vm.selectedCalendarEventDateId,
                  "team-id": _vm.selectedTeamId,
                },
                on: { onCancel: _vm.hideModal, onSubmit: _vm.submitted },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }