





























































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ContractDetails, ContractHour } from '../models/contract-details.model';
import { ContractAmountType } from '@/shared/enums/contract-amount-type.enum';
import { ToastHelper } from '../../../shared/helpers/toast.helper';
import BookHoursModal from './components/BookHoursModal.vue';
import EditHoursModal from './components/EditHoursModal.vue';
import { DateHelper } from '@/shared/helpers/date.helper';
import { PriceHelper } from '@/shared/helpers/price.helper';
import { inject } from 'inversify-props';
import { IContractsApiService, identifier } from '../services/contracts.service';
import dayjs from 'dayjs';

@Component({
    components: {
        'book-hours-modal': BookHoursModal,
        'edit-hours-modal': EditHoursModal
    }
})
export default class ContractDetailsView extends Vue {
    loaded = false;

    hoursToDelete: string | null = null;
    hoursToEdit: string | null = null;

    @Action
    private loadContractDetails! : (payload: { id: string }) => Promise<void>
    @Action
    private resetContractDetails!: () => void;
    @Action
    private setLoading!: (loading: boolean) => Promise<void>

    @inject(identifier)
    private contractApiService!: IContractsApiService;

    @Getter
    contractDetails!: ContractDetails;
    @Getter
    clubId!: string;

    async created() {
        await this.reloadDetails();
    }

    async reloadDetails() {
        try {
            this.loaded = false;

            await this.setLoading(true);
            await this.loadContractDetails({
                id: this.contractId
            });
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
        finally {         
            await this.setLoading(false);
            this.loaded = true;   
        }
    }

    destroyed() {
        this.resetContractDetails();
    }

    get contractId() : string {
        return this.$route.params.id;
    }

    get tableHeaders(): { key: string, label: string }[] {
        return [
            { key: 'date', label: `${this.$t('contracts.details.hoursTable.dateHeader')}` },
            { key: 'registeredDate', label: `${this.$t('contracts.details.hoursTable.registeredDate')}` },
            { key: 'type', label: `${this.$t('contracts.details.hoursTable.type')}` },
            { key: 'team', label: `${this.$t('contracts.details.hoursTable.team')}` },
            { key: 'amountTotal', label: `${this.$t('contracts.details.hoursTable.hours')}` },
            { key: 'comment', label: `${this.$t('contracts.details.hoursTable.comment')}` },
            { key: 'approved', label: `${this.$t('contracts.details.hoursTable.approved')}` },
            { key: 'paid', label: `${this.$t('contracts.details.hoursTable.paid')}` },
            { key: 'id', label: '' }
        ]
    }

    get registeredHours() : ContractHour[] {
        if (this.contractDetails === null) {
            return [];
        }

        let hours: ContractHour[] = [];
        this.contractDetails.registeredHours.forEach((e) => hours.push(e.registeredHour))
        return hours;
    }

    get totalRegisteredHours() : string {
        if (this.contractDetails === null) {
            return '';
        }

        const totalHours = this.contractDetails.registeredHours.reduce((sum, item) => sum + item.registeredHour.hours, 0);
        return this.convertFloatToTimeText(totalHours);
    }

    get totalPaid() : string {
        if (this.contractDetails === null) {
            return '';
        }

        const totalPaid = this.contractDetails.registeredHours.reduce((sum, item) => sum + (item.registeredHour?.paidAmount || 0), 0);
        return this.getPrice(totalPaid)
    }

    get showHours() : boolean {
        if (this.contractDetails === null || this.contractDetails.amountType === null) {
            return false;
        }

        return this.contractDetails.amountType === 'perHour' || this.contractDetails.amountType === 'perItem'
    }

    get contractStartDate() : string | null {
        if (this.contractDetails === null) {
            return null;
        }

        return dayjs(this.contractDetails.startDate).format('YYYY-MM-DD');
    }

    get contractEndDate() : string | null {
        if (this.contractDetails === null || this.contractDetails.endDate === null) {
            return null;
        }

        return dayjs(this.contractDetails.endDate).format('YYYY-MM-DD');
    }

    
    getDate(val: string | null | undefined) : string {
        if (!val) {
            return '-';
        }

        return DateHelper.parseDate(val);
    }

    getPrice(value: number) : string {
        return PriceHelper.getPrice(value);
    }

    convertFloatToTimeText(time: number) : string {
        const remainder = time % 1;
        const minutes = Math.round(remainder * 60);
        const hours = (time - remainder);

        const hoursString = `${this.$t('contracts.details.hours')}`;
        const minutesString = `${this.$t('contracts.details.minutes')}`;

        return `${hours} ${hoursString.toLowerCase()}, ${minutes} ${minutesString.toLowerCase()}`;
    }

    getAmountWithType(amount?: number | null, type?: ContractAmountType | null) : string {
        let typeName = '';
        switch (type) {
            case ContractAmountType.PerHour:
                typeName = `${this.$t('shared.contractAmountType.perHour')}`;
                break;
            case ContractAmountType.PerPeriod:
                typeName = `${this.$t('shared.contractAmountType.perPeriod')}`;
                break;
            case ContractAmountType.PerItem:
                typeName = `${this.$t('shared.contractAmountType.perItem')}`;
                break;
            case ContractAmountType.NoCompensation:
                typeName = `${this.$t('shared.contractAmountType.noCompensation')}`;
                break;
            default:
                typeName = '';
                break;
        }

        return `€${this.parsePrice(amount)} ${typeName.toLowerCase()}`;
    }

    getHoursDate(data: any) : string {
        const date = dayjs((data.value as string));

        const startDate = `${date.format('DD-MM-YYYY')} ${data.item.startTime}`;
        const endDate = `${date.format('DD-MM-YYYY')} ${data.item.endTime}`;

        const startTime = dayjs(startDate, "DD-MM-YYYY HH:mm:ss").format('HH:mm');
        const endTime = dayjs(endDate, "DD-MM-YYYY HH:mm:ss").format('HH:mm');

        return `${date.format('dddd D MMM YYYY')} ${startTime} - ${endTime}`;
    }

    getRegisteredDate(data: any) : string {
        return DateHelper.parseDate(data, 'dddd D MMM YYYY HH:mm');
    }

    getTotalPrice(data: any) : string {
        return this.getPrice(data * (this.contractDetails?.amount || 0))
    }

    parsePrice(data: any) : string {
        return this.getPrice(data)
    }

    getApprovedInfo(data: any) : string {
        return data.value ? `Ja (${data.item.approvedBy})` : 'Nee'
    }

    getPaidInfo(value: boolean) : string {
        return value ? 'Ja' : 'Nee'
    }

    showBookHoursModal() : void {
        (this.$refs['book-hours-modal'] as any).show();
    }

    showEditHourModal(id: string) : void {
        this.hoursToEdit = id;
        this.$bvModal.show('edit-hrs-modal');
    }

    onEditHoursModalHide() : void {
        this.hoursToEdit = null;
    }

    confirmDeleteHour(id: string) : void {
        this.hoursToDelete = id;
        this.$bvModal.show('confirm-delete-modal');
    }

    async deleteHours() : Promise<void> {
        try {
            if (this.hoursToDelete === null) {
                return;
            }

            await this.setLoading(true);
            await this.contractApiService.deleteContractHours(this.clubId, this.contractDetails.id, this.hoursToDelete);
            await this.reloadDetails();

            this.hoursToDelete = null;

            ToastHelper.showSuccess(this.$bvToast, this.$t('contracts.details.deleteSuccess'));
        }
        catch (ex) {            
            ToastHelper.showError(this.$bvToast, `${this.$t('shared.toast.errod')}`);
        }
        finally {
            await this.setLoading(false);
        }
    }
}
